<template>
    <div id="editTagPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editTag"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="tag_editBLETag"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!this.associatedAsset">
                                    <router-link :to="{ name: 'newAsset', params: { tagId: this.tagId } }" id="tags_createNewAssetFromThisTagButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10" data-cy="createNewAssetFromTagBtn">
                                        <i class="fa-link fa kt-margin-r-5"></i>
                                        {{ $t("tag_associatedAssetFromThisTag") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("tag_editBLETag") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group row validated">
                                                    <div class="col-lg-6">
                                                        <label for="newTagForm_serialNumberInput">{{ $t("tag_serialNumberLabel") }}</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-barcode"></i></span>
                                                            </div>
                                                            <input v-model="serialNumber" @input="$v.serialNumber.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="newTagForm_serialNumberInput" :placeholder="$t('tag_enterSerialNumber')" />
                                                            <div v-if="!$v.serialNumber.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="!$v.serialNumber.minLen" class="invalid-feedback">
                                                                {{ $t("error_minLengthMsg", [$v.serialNumber.$params.minLen.min]) }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted"> {{ $t("tag_serialNumberExample") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="newTagForm_macAddressInput">{{ $t("tag_macAddressLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-code"></i></span>
                                                            </div>
                                                            <input v-model="macAddress" @input="$v.macAddress.$touch()" @keyup="onChangeForm" type="text" class="form-control toUppercase" id="newTagForm_macAddressInput" :placeholder="$t('tag_enterMacAddress')" />
                                                            <div v-if="!$v.macAddress.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="$v.macAddress.$error" class="invalid-feedback">
                                                                {{ $t("error_invalidMacAddress") }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_macAddressExample") }}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-6">
                                                        <label for="batteryInstalDateDatePicker">{{ $t("tag_batteryInstalDateLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-calendar"></i></span>
                                                            </div>
                                                            <input type="text" class="form-control" id="batteryInstalDateDatePicker" readonly="" :placeholder="$t('tag_selectInstalDate')" />
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_batteryInstalDateExample") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="editTagForm_tagTypeInput">{{ $t("tag_tagTypeLabel") }} *</label>
                                                        <div id="editGatewayModel" class="gateway-form-model-input">
                                                            <select id="editTagForm_tagTypeInput" class="form-control kt-select2">
                                                                <option></option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editTagCreateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editTagCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--begin::isAssociatedAsset-->
                                    <div class="kt-portlet" v-if="associatedAsset != null">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z" fill="#000000" opacity="0.3" transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641) " />
                                                            <path d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z" fill="#000000" transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359) " />
                                                            <path
                                                                d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z"
                                                                fill="#000000"
                                                                opacity="0.3"
                                                                transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146) "
                                                            />
                                                            <path
                                                                d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z"
                                                                fill="#000000"
                                                                opacity="0.3"
                                                                transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961) "
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">{{ $t("tag_associatedAsset") }}</h3>
                                            </div>
                                            <div class="kt-portlet__head-toolbar">
                                                <router-link tag="button" :to="{ name: 'editAsset', params: { assetId: this.associatedAsset.id } }" id="editTag_editAssetButton" class="btn btn-sm btn-brand">
                                                    <i class="la la-edit marginTM4"></i>
                                                    {{ $t("common_edit") }}
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <div class="form-group-marginless row">
                                                <div class="col-lg-6">
                                                    <label>{{ $t("asset_assetName") }}</label>
                                                    <input id="editTag_AssetNameInput" type="text" class="form-control" :placeholder="$t('asset_inputInfoAssetName')" disabled="disabled" v-model="associatedAsset.name" />
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>{{ $t("asset_assetUnit") }}</label>
                                                    <input id="editTag_AssetUnitInput" type="text" class="form-control" :placeholder="$t('asset_inputInfoAssetName')" disabled="disabled" v-model="associatedAsset.unit" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::isAssociatedAsset-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, macAddress, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";

export default {
    data() {
        return {
            serialNumber: "",
            macAddress: "",
            batteryInstallationDate: moment().toISOString(),
            tagTypeId: "",
            siteId: this.$route.params.siteId,
            tagId: this.$route.params.tagId,
            isFormDataChanged: false,
            tagLoaded: false,
            associatedAsset: null,
            tagTypes: [],
            tagModelHeadStyle: "",
            tagModelBodyStyle: "",
            resizeTimeoutId: null
        };
    },
    created: function() {
        console.log("Component(editTag)::created() - called");
        window.addEventListener("resize", this.onResize);
        this.getTagTypes({ siteId: this.siteId });
    },
    mounted: function() {
        let self = this;
        console.log("Component(editTag)::mounted() - Init metronic layout");
        KTLayout.init();

        // Init datepicker
        let selector = $("#batteryInstalDateDatePicker");
        commonVueHelper.initDatePickerTag(selector, "bottom left", moment().toDate());
        selector.on("change", e => {
            let oldInstallDate = self.batteryInstallationDate;
            self.batteryInstallationDate = commonVueHelper.getDatePickerISOString(selector);
            if (self.tagLoaded && self.batteryInstallationDate !== oldInstallDate) {
                self.onChangeForm(e);
            }
        });
    },
    beforeDestroy: function() {
        console.log("Component(editTag)::beforeDestroy() - called");
        // Reset component data
        this.serialNumber = null;
        this.macAddress = null;
        this.batteryInstallationDate = null;
        this.tagTypeId = null;
        this.associatedAsset = null;
        this.tagTypes = [];
        if ($("#batteryInstalDateDatePicker").length !== 0) {
            $("#batteryInstalDateDatePicker").datepicker("destroy");
        }
        // Reset store
        this.resetTagsState();
        this.resetTagTypesState();
    },
    destroyed: function() {
        console.log("Component(editTag)::destroyed() - called");
        window.removeEventListener("resize", this.onResize);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        serialNumber: {
            required,
            minLen: minLength(4)
        },
        macAddress: {
            required,
            macAddress: macAddress()
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        tagTypeObjsByName: function(list) {
            console.log("Component(editTag)::watch(tagTypeObjsByName) called with : ", list);
            // Initialisation of tagTypes array (used for v-for)
            let mySelf = this;
            mySelf.tagTypes = [];
            if (list && Object.keys(list).length > 0) {
                Object.keys(list).forEach(key => {
                    mySelf.tagTypes.push(list[key]);
                });
                setTimeout(() => {
                    const site = this.currentSite;
                    let BLEGatewayType = "";
                    if(site) {
                        BLEGatewayType = site.BLEGatewaysType;
                    }
                    if (BLEGatewayType === "OMNIACCESS_OEM") {
                        _.remove(mySelf.tagTypes, (tagType) => tagType.name === "ASSET");
                    }
                }, 200);
                // Get the tag by its id
                const payload = {
                    siteId: mySelf.siteId,
                    tagId: mySelf.tagId
                };
                mySelf.getTagById(payload);
            }
        },

        currentTag: function(tag) {
            console.log("Component(editTag)::watch(currentTag) - called with : ", tag);
            this.associatedAsset = null;
            if (tag) {
                this.serialNumber = tag.serialNumber;
                this.macAddress = tag.macAddress;
                let tagTypeKey = "OTHER";
                if (tag.tagType) {
                    this.tagTypeId = tag.tagType.id;
                    tagTypeKey = tag.tagType.name;
                }

                commonVueHelper.setDatePickerVal($("#batteryInstalDateDatePicker"), moment(tag.batteryInstallationDate).toDate());

                // Change border of element to blue
                // $(".tagBorder").css("border", "1px solid #ebedf2");
                // $(".tagLabel").css("color", "#6c7293");

                // $("input[name=" + tagTypeKey + "]")
                //     .closest(".tagBorder")
                //     .css("border", "1px solid #5d78ff");
                // $("input[name=" + tagTypeKey + "]")
                //     .closest(".tagBorder")
                //     .find(".tagLabel")
                //     .css("color", "#5d78ff");

                this.tagLoaded = true;

                this.updateAssociatedAsset(tag);
                this.initTagModelsSelect2();
                // Resize tag models spans
                this.onResize();
            }

        },

        user: function(user) {
            console.log("Component(editTag)::watch(user) - called with : ", user);
            if (user) {
                // Translate tag models
                this.updateTagTypes();
                // Translate associated asset unit
                this.updateAssociatedAsset(this.currentTag);
                // Resize tag models spans
                this.onResize();
                this.initTagModelsSelect2();
            }
        },

        tagModelHeadStyle: function(style) {
            console.log("Component(editTag)::watch(tagModelHeadStyle) - called with : ", style);
        },

        tagModelBodyStyle: function(style) {
            console.log("Component(editTag)::watch(tagModelBodyStyle) - called with : ", style);
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "currentTag", "tagTypeObjsByName", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getTagById", "updateTag", "getTagTypes", "resetTagsState", "resetTagTypesState"]),

        getDataTagTypes() {
            const tagtypes = _.map(this.tagTypes, tagType => {
                return { id: tagType.id, text: tagType.fullRef, "data-image": tagType.image};
            })
            return tagtypes;

        },

        initTagModelsSelect2() {
            var self = this;

            const formatState = (state) => {
                // console.log(state)
                if (!state.id) {
                    return state.text
                }
                let tagType = _.find(self.tagTypes, {id: state.id});
                if (!tagType.img) {
                    tagType.img = "/assets/tags/universal.svg";
                }
                let $state = $(
                    `<span> <img src="${tagType.img}" class="tagsIconPreview" style="margin-right: 10px" /> ${state.text} </span>`
                );
                return $state;
            };


            const dataTagModels = self.getDataTagTypes();
            const tagTypeSelector = $("#editTagForm_tagTypeInput");
            commonVueHelper.destroySelect2(tagTypeSelector);
            $("#editTagForm_tagTypeInput")
                .select2({
                    allowClear: true,
                    placeholder: i18n.t("tag_tagEnterTagModel"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        },
                        searching: function () {
                            return i18n.t("common_searchDots");
                        },
                        placeholder: function () {
                            return i18n.t("common_searchBySerial");
                        }
                    },
                    data: dataTagModels,
                    templateResult: formatState
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.tagTypeId = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        self.onChangeForm();
                    }
                });

            // Set value selected
            if (self.tagTypeId) {
                tagTypeSelector.val(self.tagTypeId);
            } else {
                tagTypeSelector.val(dataTagModels[0]['id']);
            }
            tagTypeSelector.trigger({ type: "change", params: { initialInit: true } })
        },

        // Update tag types translation with current user language
        updateTagTypes() {
            // Saved selected model
            const tagTypeIdSaved = this.tagTypeId;
            this.tagTypeId = null;
            // Update models translation
            for (let i=0; i<this.tagTypes.length; i++) {
                this.tagTypes[i] = commonVueHelper.getTagTypeItem(this.tagTypes[i]);
            }
            // Force refresh tag models
            this.tagTypeId = tagTypeIdSaved;
        },

        updateAssociatedAsset(tag) {
            if (tag && tag.asset && tag.asset.id) {
                this.associatedAsset = {
                    id: tag.asset.id,
                    name: tag.asset.name
                };
                if (tag.asset.assetUnit.isDefault) {
                    this.associatedAsset.unit = i18n.t(tag.asset.assetUnit.name);
                } else {
                    this.associatedAsset.unit = tag.asset.assetUnit.name;
                }
            }
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editTag)::onCancelButton() - called");
            this.$router.push({ name: "tags", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(editTag)::onUpdateButton() - called");
            const data = {
                siteId: this.siteId,
                tagId: this.tagId,
                serialNumber: this.serialNumber,
                macAddress: this.macAddress,
                batteryInstallationDate: this.batteryInstallationDate,
                tagTypeId: this.tagTypeId
            };
            this.updateTag(data);
        },

        onChangeForm(event) {
            this.isFormDataChanged = true;
        },

        /**
         * Function called by "resize" event listener
         */
        onResize(event) {
            console.log("Component(editTag)::onResize() - called", event);
            // No height style, this to render the default height of models style
            this.tagModelHeadStyle = "";
            this.tagModelBodyStyle = "";
            // Waiting all displayed before resize tag models span
            if (this.resizeTimeoutId) {
                clearTimeout(this.resizeTimeoutId);
            }
            this.resizeTimeoutId = setTimeout(this.resizeTagModels, 100);
        },

        /**
         * Resize the tag models with the same height.
         */
        resizeTagModels() {
            console.log("Component(editTag)::resizeTagModels() - called");
            // Get the max height of tag model head style and set all model head with this height
            let maxTagModelHeadHeight = 0;
            const selectorTagModelHead = $(".tagModelOptionHead");
            if (selectorTagModelHead && selectorTagModelHead.length > 0) {
                selectorTagModelHead.each(function() {
                    if ($(this) && $(this).height() > maxTagModelHeadHeight) {
                        maxTagModelHeadHeight = $(this).height();
                    }
                });
                if (maxTagModelHeadHeight) {
                    this.tagModelHeadStyle = "height: " + maxTagModelHeadHeight + "px";
                }
            }

            // Get the max height of tag model body and set all model body height with this height
            let maxTagModelBodyHeight = 0;
            const selectorTagModelBody = $(".tagModelOptionBody");
            if (selectorTagModelBody && selectorTagModelBody.length > 0) {
                selectorTagModelBody.each(function() {
                    if ($(this) && $(this).height() > maxTagModelBodyHeight) {
                        maxTagModelBodyHeight = $(this).height();
                    }
                });
                if (maxTagModelBodyHeight) {
                    this.tagModelBodyStyle = "height: " + maxTagModelBodyHeight + "px";
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditTag", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.cursorPointer {
    cursor: pointer;
}
.noPortletBorder {
    border: none !important;
}
</style>
